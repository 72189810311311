/**
 * Exports the Sass partial for common-ng.
 *
 * Reference as follows in component.scss files:
 * 
 * 1. Import common-ng library to package.json
 *    
 *    > npm install @shopliftr/common-ng --save 
 *
 * 2. Add references to <component-name>.component.scss files
 *
 *   @use "@shopliftr/common-ng" as common;
 *   ...
 *   color: common.$color-primary-base
 *   font-size: common.$font-size-small
 *   ...
 *   @include common.rounded()
 *   ...
 *   // etc.
 *
 */
/**
 * COMMON-NG THEME
 *
 * Exports all partials for the common-ng library.
 *
 * New components should be added to this partial and bootstrapped in the all-component-themes mixin.
 */
/**
 * SHOPLIFTR BASE THEME
 *
 * Assembles the ShopLiftr base theme partials and the logic for
 * bootstrapping Angular Material library. All variables, mixins
 * and styles are exported from this file.
 *
 * This partial is forwarded by common-ng and should not be
 * directly referenced by any application code.
 */
/**
 * THEME-CONFIG
 *
 * Defines the base theme configuration for Angular Material and all inherited controls.
 */
/**
 * PALETTE
 *
 * Main ShopLiftr Theme Color Palette
 */
/**
 * Function to bootstrap an alternate theme and apply it to the current palette color variables
 */
/**
 * Functions for getting default theme values
 */
/**
 * Functions for getting values from the currently defined theme
 */
/**
 * Functions for overriding the default theme with new configuration.
 *
 * All function parameters are optional and default to ShopLiftr base theme
 * values if not provided.
 */
/**
 * Default values for base theme
 */
/**
 * Map to hold the defined theme for the current configuration values.
 *
 * This map is not exported and should not be referenced directly.
 * Use get-theme() and get-font-family() to obtain the theme properties.
 */
/**
 * VARS
 *
 * General-purpose variables
 *  - See _palette.scss for color variables
 *  - See _fonts.scss for font variables
 *
 */
/*********************
	IMAGES
*********************/
/*********************
	LAYOUT
*********************/
/* 1.375 */
/**************************************************
	BREAKPOINTS
**************************************************/
/**
 * FONTS
 *
 * All font related classes and variables are in this file
 */
/**
 * MIXINS
 *
 * Common mixins used in the library controls
 */
/**
 *  LAYOUT
 *
 *  Contains basic layout, forms, and typeography styling
 */
/**
* Used to setup a item as a row (horizontal) based flex container
* Aligns items vertically to center
* justfies content horizontally space-evenly
*/
.mgd-layout-page-content-section-filter, .mgd-layout-action-buttons-section-no-border, .mgd-layout-action-buttons-section, mat-card-actions, .mgd-list-empty-state-container, .mgd-list-filter, .mgd-list-filter .mgd-list-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/**
* Used to setup a item as a colomn (vertical) based flex container
* Aligns items horizontal to center 
* justfies content vertically as space-evenly
*/
/**
* Adds a standard gap size to the flex items
* Should be applied to a %mgd-layout-flex-row or %mgd-layout-flex-col
*/
.mgd-layout-page-content-section-filter, .mgd-layout-action-buttons-section-no-border, .mgd-layout-action-buttons-section, mat-card-actions, .mgd-list-filter, .mgd-list-filter .mgd-list-actions {
  gap: 1rem;
}

/**
 * MATERIAL OVERRIDES
 *
 * Custom Material styling not contained.
 */
/**
 * TABLES
 *
 * Custom styling for Material 13 tables
 */
/**
 * SHOPLIFTR BASE THEME
 *
 * Assembles the ShopLiftr base theme partials and the logic for
 * bootstrapping Angular Material library. All variables, mixins
 * and styles are exported from this file.
 *
 * This partial is forwarded by common-ng and should not be
 * directly referenced by any application code.
 */
/**
 * COMMON-NG THEME
 *
 * Exports all partials for the common-ng library.
 *
 * New components should be added to this partial and bootstrapped in the all-component-themes mixin.
 */
.mgd-admin-priority {
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #f8fafd;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin: 0 4px 0 0;
  text-align: center;
  width: 21px;
}
.mgd-admin-priority.priority-1 {
  background-color: #3162a9;
}
.mgd-admin-priority.priority-2 {
  background-color: #4691db;
}
.mgd-admin-priority.priority-3 {
  background-color: #96c7ed;
}
.mgd-admin-priority.priority-4 {
  background-color: #e3ebf7;
}
.mgd-admin-priority.cancelled, .mgd-admin-priority.rejected {
  color: #fd2b2b;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #091a28;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #05172a;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #fd2b2b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #091a28;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #091a28;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #05172a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #05172a;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #05172a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #05172a;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #fd2b2b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fd2b2b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #091a28;
  --mdc-linear-progress-track-color: rgba(9, 26, 40, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #05172a;
  --mdc-linear-progress-track-color: rgba(5, 23, 42, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #fd2b2b;
  --mdc-linear-progress-track-color: rgba(253, 43, 43, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #091a28;
  --mdc-filled-text-field-focus-active-indicator-color: #091a28;
  --mdc-filled-text-field-focus-label-text-color: rgba(9, 26, 40, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #fd2b2b;
  --mdc-filled-text-field-error-focus-label-text-color: #fd2b2b;
  --mdc-filled-text-field-error-label-text-color: #fd2b2b;
  --mdc-filled-text-field-error-caret-color: #fd2b2b;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #fd2b2b;
  --mdc-filled-text-field-error-focus-active-indicator-color: #fd2b2b;
  --mdc-filled-text-field-error-hover-active-indicator-color: #fd2b2b;
  --mdc-outlined-text-field-caret-color: #091a28;
  --mdc-outlined-text-field-focus-outline-color: #091a28;
  --mdc-outlined-text-field-focus-label-text-color: rgba(9, 26, 40, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #fd2b2b;
  --mdc-outlined-text-field-error-focus-label-text-color: #fd2b2b;
  --mdc-outlined-text-field-error-label-text-color: #fd2b2b;
  --mdc-outlined-text-field-error-hover-label-text-color: #fd2b2b;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #fd2b2b;
  --mdc-outlined-text-field-error-hover-outline-color: #fd2b2b;
  --mdc-outlined-text-field-error-outline-color: #fd2b2b;
  --mat-form-field-focus-select-arrow-color: rgba(9, 26, 40, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #fd2b2b;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #05172a;
  --mdc-filled-text-field-focus-active-indicator-color: #05172a;
  --mdc-filled-text-field-focus-label-text-color: rgba(5, 23, 42, 0.87);
  --mdc-outlined-text-field-caret-color: #05172a;
  --mdc-outlined-text-field-focus-outline-color: #05172a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(5, 23, 42, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(5, 23, 42, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #fd2b2b;
  --mdc-filled-text-field-focus-active-indicator-color: #fd2b2b;
  --mdc-filled-text-field-focus-label-text-color: rgba(253, 43, 43, 0.87);
  --mdc-outlined-text-field-caret-color: #fd2b2b;
  --mdc-outlined-text-field-focus-outline-color: #fd2b2b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(253, 43, 43, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(253, 43, 43, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(9, 26, 40, 0.87);
  --mat-select-invalid-arrow-color: rgba(253, 43, 43, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(5, 23, 42, 0.87);
  --mat-select-invalid-arrow-color: rgba(253, 43, 43, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(253, 43, 43, 0.87);
  --mat-select-invalid-arrow-color: rgba(253, 43, 43, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #f8fafd;
  --mdc-chip-elevated-container-color: #091a28;
  --mdc-chip-elevated-selected-container-color: #091a28;
  --mdc-chip-elevated-disabled-container-color: #091a28;
  --mdc-chip-flat-disabled-selected-container-color: #091a28;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #f8fafd;
  --mdc-chip-selected-label-text-color: #f8fafd;
  --mdc-chip-with-icon-icon-color: #f8fafd;
  --mdc-chip-with-icon-disabled-icon-color: #f8fafd;
  --mdc-chip-with-icon-selected-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #f8fafd;
  --mat-chip-selected-disabled-trailing-icon-color: #f8fafd;
  --mat-chip-selected-trailing-icon-color: #f8fafd;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #f8fafd;
  --mdc-chip-elevated-container-color: #05172a;
  --mdc-chip-elevated-selected-container-color: #05172a;
  --mdc-chip-elevated-disabled-container-color: #05172a;
  --mdc-chip-flat-disabled-selected-container-color: #05172a;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #f8fafd;
  --mdc-chip-selected-label-text-color: #f8fafd;
  --mdc-chip-with-icon-icon-color: #f8fafd;
  --mdc-chip-with-icon-disabled-icon-color: #f8fafd;
  --mdc-chip-with-icon-selected-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #f8fafd;
  --mat-chip-selected-disabled-trailing-icon-color: #f8fafd;
  --mat-chip-selected-trailing-icon-color: #f8fafd;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #f8fafd;
  --mdc-chip-elevated-container-color: #fd2b2b;
  --mdc-chip-elevated-selected-container-color: #fd2b2b;
  --mdc-chip-elevated-disabled-container-color: #fd2b2b;
  --mdc-chip-flat-disabled-selected-container-color: #fd2b2b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #f8fafd;
  --mdc-chip-selected-label-text-color: #f8fafd;
  --mdc-chip-with-icon-icon-color: #f8fafd;
  --mdc-chip-with-icon-disabled-icon-color: #f8fafd;
  --mdc-chip-with-icon-selected-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #f8fafd;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #f8fafd;
  --mat-chip-selected-disabled-trailing-icon-color: #f8fafd;
  --mat-chip-selected-trailing-icon-color: #f8fafd;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #365168;
  --mdc-switch-selected-handle-color: #365168;
  --mdc-switch-selected-hover-state-layer-color: #365168;
  --mdc-switch-selected-pressed-state-layer-color: #365168;
  --mdc-switch-selected-focus-handle-color: #091a28;
  --mdc-switch-selected-hover-handle-color: #091a28;
  --mdc-switch-selected-pressed-handle-color: #091a28;
  --mdc-switch-selected-focus-track-color: #778ba1;
  --mdc-switch-selected-hover-track-color: #778ba1;
  --mdc-switch-selected-pressed-track-color: #778ba1;
  --mdc-switch-selected-track-color: #778ba1;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4084ce;
  --mdc-switch-selected-handle-color: #4084ce;
  --mdc-switch-selected-hover-state-layer-color: #4084ce;
  --mdc-switch-selected-pressed-state-layer-color: #4084ce;
  --mdc-switch-selected-focus-handle-color: #264589;
  --mdc-switch-selected-hover-handle-color: #264589;
  --mdc-switch-selected-pressed-handle-color: #264589;
  --mdc-switch-selected-focus-track-color: #72b1e5;
  --mdc-switch-selected-hover-track-color: #72b1e5;
  --mdc-switch-selected-pressed-track-color: #72b1e5;
  --mdc-switch-selected-track-color: #72b1e5;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #ed1d2b;
  --mdc-switch-selected-handle-color: #ed1d2b;
  --mdc-switch-selected-hover-state-layer-color: #ed1d2b;
  --mdc-switch-selected-pressed-state-layer-color: #ed1d2b;
  --mdc-switch-selected-focus-handle-color: #bf000f;
  --mdc-switch-selected-hover-handle-color: #bf000f;
  --mdc-switch-selected-pressed-handle-color: #bf000f;
  --mdc-switch-selected-focus-track-color: #ef666b;
  --mdc-switch-selected-hover-track-color: #ef666b;
  --mdc-switch-selected-pressed-track-color: #ef666b;
  --mdc-switch-selected-track-color: #ef666b;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #091a28;
  --mdc-radio-selected-hover-icon-color: #091a28;
  --mdc-radio-selected-icon-color: #091a28;
  --mdc-radio-selected-pressed-icon-color: #091a28;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #091a28;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #05172a;
  --mdc-radio-selected-hover-icon-color: #05172a;
  --mdc-radio-selected-icon-color: #05172a;
  --mdc-radio-selected-pressed-icon-color: #05172a;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #05172a;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fd2b2b;
  --mdc-radio-selected-hover-icon-color: #fd2b2b;
  --mdc-radio-selected-icon-color: #fd2b2b;
  --mdc-radio-selected-pressed-icon-color: #fd2b2b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #fd2b2b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #091a28;
  --mdc-slider-focus-handle-color: #091a28;
  --mdc-slider-hover-handle-color: #091a28;
  --mdc-slider-active-track-color: #091a28;
  --mdc-slider-inactive-track-color: #091a28;
  --mdc-slider-with-tick-marks-inactive-container-color: #091a28;
  --mdc-slider-with-tick-marks-active-container-color: #f8fafd;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #091a28;
  --mat-slider-hover-state-layer-color: rgba(9, 26, 40, 0.05);
  --mat-slider-focus-state-layer-color: rgba(9, 26, 40, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #05172a;
  --mat-slider-hover-state-layer-color: rgba(5, 23, 42, 0.05);
  --mat-slider-focus-state-layer-color: rgba(5, 23, 42, 0.2);
  --mdc-slider-handle-color: #05172a;
  --mdc-slider-focus-handle-color: #05172a;
  --mdc-slider-hover-handle-color: #05172a;
  --mdc-slider-active-track-color: #05172a;
  --mdc-slider-inactive-track-color: #05172a;
  --mdc-slider-with-tick-marks-inactive-container-color: #05172a;
  --mdc-slider-with-tick-marks-active-container-color: #f8fafd;
}
html .mat-warn {
  --mat-slider-ripple-color: #fd2b2b;
  --mat-slider-hover-state-layer-color: rgba(253, 43, 43, 0.05);
  --mat-slider-focus-state-layer-color: rgba(253, 43, 43, 0.2);
  --mdc-slider-handle-color: #fd2b2b;
  --mdc-slider-focus-handle-color: #fd2b2b;
  --mdc-slider-hover-handle-color: #fd2b2b;
  --mdc-slider-active-track-color: #fd2b2b;
  --mdc-slider-inactive-track-color: #fd2b2b;
  --mdc-slider-with-tick-marks-inactive-container-color: #fd2b2b;
  --mdc-slider-with-tick-marks-active-container-color: #f8fafd;
}

html {
  --mdc-slider-label-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #091a28;
  --mdc-radio-selected-hover-icon-color: #091a28;
  --mdc-radio-selected-icon-color: #091a28;
  --mdc-radio-selected-pressed-icon-color: #091a28;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #05172a;
  --mdc-radio-selected-hover-icon-color: #05172a;
  --mdc-radio-selected-icon-color: #05172a;
  --mdc-radio-selected-pressed-icon-color: #05172a;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fd2b2b;
  --mdc-radio-selected-hover-icon-color: #fd2b2b;
  --mdc-radio-selected-icon-color: #fd2b2b;
  --mdc-radio-selected-pressed-icon-color: #fd2b2b;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #091a28;
  --mdc-checkbox-selected-hover-icon-color: #091a28;
  --mdc-checkbox-selected-icon-color: #091a28;
  --mdc-checkbox-selected-pressed-icon-color: #091a28;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #091a28;
  --mdc-checkbox-selected-hover-state-layer-color: #091a28;
  --mdc-checkbox-selected-pressed-state-layer-color: #091a28;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #05172a;
  --mdc-checkbox-selected-hover-icon-color: #05172a;
  --mdc-checkbox-selected-icon-color: #05172a;
  --mdc-checkbox-selected-pressed-icon-color: #05172a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #05172a;
  --mdc-checkbox-selected-hover-state-layer-color: #05172a;
  --mdc-checkbox-selected-pressed-state-layer-color: #05172a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #fd2b2b;
  --mdc-checkbox-selected-hover-icon-color: #fd2b2b;
  --mdc-checkbox-selected-icon-color: #fd2b2b;
  --mdc-checkbox-selected-pressed-icon-color: #fd2b2b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fd2b2b;
  --mdc-checkbox-selected-hover-state-layer-color: #fd2b2b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fd2b2b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #091a28;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #091a28;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #091a28;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #091a28;
  --mat-tab-header-active-ripple-color: #091a28;
  --mat-tab-header-inactive-ripple-color: #091a28;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #091a28;
  --mat-tab-header-active-hover-label-text-color: #091a28;
  --mat-tab-header-active-focus-indicator-color: #091a28;
  --mat-tab-header-active-hover-indicator-color: #091a28;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #05172a;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #05172a;
  --mat-tab-header-active-ripple-color: #05172a;
  --mat-tab-header-inactive-ripple-color: #05172a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #05172a;
  --mat-tab-header-active-hover-label-text-color: #05172a;
  --mat-tab-header-active-focus-indicator-color: #05172a;
  --mat-tab-header-active-hover-indicator-color: #05172a;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #fd2b2b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fd2b2b;
  --mat-tab-header-active-ripple-color: #fd2b2b;
  --mat-tab-header-inactive-ripple-color: #fd2b2b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fd2b2b;
  --mat-tab-header-active-hover-label-text-color: #fd2b2b;
  --mat-tab-header-active-focus-indicator-color: #fd2b2b;
  --mat-tab-header-active-hover-indicator-color: #fd2b2b;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #091a28;
  --mat-tab-header-with-background-foreground-color: #f8fafd;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #05172a;
  --mat-tab-header-with-background-foreground-color: #f8fafd;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #fd2b2b;
  --mat-tab-header-with-background-foreground-color: #f8fafd;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #05172a;
  --mdc-checkbox-selected-hover-icon-color: #05172a;
  --mdc-checkbox-selected-icon-color: #05172a;
  --mdc-checkbox-selected-pressed-icon-color: #05172a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #05172a;
  --mdc-checkbox-selected-hover-state-layer-color: #05172a;
  --mdc-checkbox-selected-pressed-state-layer-color: #05172a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #091a28;
  --mdc-checkbox-selected-hover-icon-color: #091a28;
  --mdc-checkbox-selected-icon-color: #091a28;
  --mdc-checkbox-selected-pressed-icon-color: #091a28;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #091a28;
  --mdc-checkbox-selected-hover-state-layer-color: #091a28;
  --mdc-checkbox-selected-pressed-state-layer-color: #091a28;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #f8fafd;
  --mdc-checkbox-selected-focus-icon-color: #fd2b2b;
  --mdc-checkbox-selected-hover-icon-color: #fd2b2b;
  --mdc-checkbox-selected-icon-color: #fd2b2b;
  --mdc-checkbox-selected-pressed-icon-color: #fd2b2b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fd2b2b;
  --mdc-checkbox-selected-hover-state-layer-color: #fd2b2b;
  --mdc-checkbox-selected-pressed-state-layer-color: #fd2b2b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #091a28;
  --mat-text-button-state-layer-color: #091a28;
  --mat-text-button-ripple-color: rgba(9, 26, 40, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #05172a;
  --mat-text-button-state-layer-color: #05172a;
  --mat-text-button-ripple-color: rgba(5, 23, 42, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #fd2b2b;
  --mat-text-button-state-layer-color: #fd2b2b;
  --mat-text-button-ripple-color: rgba(253, 43, 43, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #091a28;
  --mdc-filled-button-label-text-color: #f8fafd;
  --mat-filled-button-state-layer-color: #f8fafd;
  --mat-filled-button-ripple-color: rgba(248, 250, 253, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #05172a;
  --mdc-filled-button-label-text-color: #f8fafd;
  --mat-filled-button-state-layer-color: #f8fafd;
  --mat-filled-button-ripple-color: rgba(248, 250, 253, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #fd2b2b;
  --mdc-filled-button-label-text-color: #f8fafd;
  --mat-filled-button-state-layer-color: #f8fafd;
  --mat-filled-button-ripple-color: rgba(248, 250, 253, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #091a28;
  --mdc-protected-button-label-text-color: #f8fafd;
  --mat-protected-button-state-layer-color: #f8fafd;
  --mat-protected-button-ripple-color: rgba(248, 250, 253, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #05172a;
  --mdc-protected-button-label-text-color: #f8fafd;
  --mat-protected-button-state-layer-color: #f8fafd;
  --mat-protected-button-ripple-color: rgba(248, 250, 253, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #fd2b2b;
  --mdc-protected-button-label-text-color: #f8fafd;
  --mat-protected-button-state-layer-color: #f8fafd;
  --mat-protected-button-ripple-color: rgba(248, 250, 253, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #091a28;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #091a28;
  --mat-outlined-button-ripple-color: rgba(9, 26, 40, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #05172a;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #05172a;
  --mat-outlined-button-ripple-color: rgba(5, 23, 42, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #fd2b2b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #fd2b2b;
  --mat-outlined-button-ripple-color: rgba(253, 43, 43, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #091a28;
  --mat-icon-button-state-layer-color: #091a28;
  --mat-icon-button-ripple-color: rgba(9, 26, 40, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #05172a;
  --mat-icon-button-state-layer-color: #05172a;
  --mat-icon-button-ripple-color: rgba(5, 23, 42, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #fd2b2b;
  --mat-icon-button-state-layer-color: #fd2b2b;
  --mat-icon-button-ripple-color: rgba(253, 43, 43, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #091a28;
  --mat-fab-foreground-color: #f8fafd;
  --mat-fab-state-layer-color: #f8fafd;
  --mat-fab-ripple-color: rgba(248, 250, 253, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #05172a;
  --mat-fab-foreground-color: #f8fafd;
  --mat-fab-state-layer-color: #f8fafd;
  --mat-fab-ripple-color: rgba(248, 250, 253, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #fd2b2b;
  --mat-fab-foreground-color: #f8fafd;
  --mat-fab-state-layer-color: #f8fafd;
  --mat-fab-ripple-color: rgba(248, 250, 253, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #091a28;
  --mat-fab-small-foreground-color: #f8fafd;
  --mat-fab-small-state-layer-color: #f8fafd;
  --mat-fab-small-ripple-color: rgba(248, 250, 253, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #05172a;
  --mat-fab-small-foreground-color: #f8fafd;
  --mat-fab-small-state-layer-color: #f8fafd;
  --mat-fab-small-ripple-color: rgba(248, 250, 253, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #fd2b2b;
  --mat-fab-small-foreground-color: #f8fafd;
  --mat-fab-small-state-layer-color: #f8fafd;
  --mat-fab-small-ripple-color: rgba(248, 250, 253, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #05172a;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #091a28;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #05172a;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #fd2b2b;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #091a28;
  --mat-badge-text-color: #f8fafd;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #05172a;
  --mat-badge-text-color: #f8fafd;
}

.mat-badge-warn {
  --mat-badge-background-color: #fd2b2b;
  --mat-badge-text-color: #f8fafd;
}

html {
  --mat-badge-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #f8fafd;
  --mat-datepicker-calendar-date-selected-state-background-color: #091a28;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(9, 26, 40, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #f8fafd;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(9, 26, 40, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(9, 26, 40, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #091a28;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(9, 26, 40, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #f8fafd;
  --mat-datepicker-calendar-date-selected-state-background-color: #05172a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(5, 23, 42, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #f8fafd;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(5, 23, 42, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(5, 23, 42, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(5, 23, 42, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #f8fafd;
  --mat-datepicker-calendar-date-selected-state-background-color: #fd2b2b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(253, 43, 43, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #f8fafd;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(253, 43, 43, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(253, 43, 43, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(253, 43, 43, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #05172a;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #fd2b2b;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #091a28;
}
.mat-icon.mat-accent {
  --mat-icon-color: #05172a;
}
.mat-icon.mat-warn {
  --mat-icon-color: #fd2b2b;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #f8fafd;
  --mat-stepper-header-selected-state-icon-background-color: #091a28;
  --mat-stepper-header-selected-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-done-state-icon-background-color: #091a28;
  --mat-stepper-header-done-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-edit-state-icon-background-color: #091a28;
  --mat-stepper-header-edit-state-icon-foreground-color: #f8fafd;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #fd2b2b;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #fd2b2b;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #f8fafd;
  --mat-stepper-header-selected-state-icon-background-color: #05172a;
  --mat-stepper-header-selected-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-done-state-icon-background-color: #05172a;
  --mat-stepper-header-done-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-edit-state-icon-background-color: #05172a;
  --mat-stepper-header-edit-state-icon-foreground-color: #f8fafd;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #f8fafd;
  --mat-stepper-header-selected-state-icon-background-color: #fd2b2b;
  --mat-stepper-header-selected-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-done-state-icon-background-color: #fd2b2b;
  --mat-stepper-header-done-state-icon-foreground-color: #f8fafd;
  --mat-stepper-header-edit-state-icon-background-color: #fd2b2b;
  --mat-stepper-header-edit-state-icon-foreground-color: #f8fafd;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #091a28;
  --mat-toolbar-container-text-color: #f8fafd;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #05172a;
  --mat-toolbar-container-text-color: #f8fafd;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #fd2b2b;
  --mat-toolbar-container-text-color: #f8fafd;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto, Tahoma, Helvetica Neue, Helvetica, Arial, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

strong,
.mgd-font-text-bold {
  font-weight: 500;
}

body {
  font-family: Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  margin: 22px 0px;
  font-size: 24px;
}
@media print {
  h1 {
    font-size: 26px;
  }
}

h2 {
  font-size: 20px;
}
@media print {
  h2 {
    font-size: 19px;
  }
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  font-weight: 700;
}

html {
  --mdc-dialog-subhead-color: rgba(0, 0, 0, .87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .8);
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0px;
  padding: 0px;
}

.mgd-layout-main-content-wrapper {
  min-height: 100vh;
}

select:focus {
  outline: 0;
}
select:hover {
  cursor: pointer;
}

header {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  margin-bottom: 35px;
}
header * {
  vertical-align: middle;
}
header a {
  color: #3872bb;
}
header a:active, header a:focus, header a:hover {
  color: #3162a9;
}
header.mgd-layout-page-title {
  background-color: #fff;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 0px;
  padding: 1.5rem;
  width: 100%;
}
header.mgd-layout-page-title > * {
  display: inline-flex;
  margin-right: 0.5rem;
  vertical-align: middle;
}
header.mgd-layout-page-title > *:last-child {
  margin-right: 0;
}
header.mgd-layout-page-title > .mgd-layout-header-info-section {
  line-height: 40px;
}
header.mgd-layout-page-title > .mgd-layout-header-info-section mat-icon {
  color: #3872bb;
  font-size: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}
header.mgd-layout-page-title > .mgd-layout-header-info-section mat-icon.mgd-layout-menu-indicator {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}
header.mgd-layout-page-title > .mgd-layout-header-info-section .mat-mdc-icon-button {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 24px;
}
header.mgd-layout-page-title > .mgd-layout-header-info-section mat-icon.mgd-layout-menu-indicator {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}
header.mgd-layout-page-title a:hover {
  cursor: pointer;
  text-decoration: underline;
}
header.mgd-layout-page-title h2,
header.mgd-layout-page-title h3 {
  margin: 0 15px 0 0;
}
header.mgd-layout-page-title h2 > span, header.mgd-layout-page-title h2 > mat-icon,
header.mgd-layout-page-title h3 > span,
header.mgd-layout-page-title h3 > mat-icon {
  line-height: 32px;
}
header.mgd-layout-page-title mat-form-field {
  display: table;
  margin-bottom: -1.25em;
  min-width: 320px;
  padding-top: calc(1.5rem / 2);
  width: 320px;
}
header.mgd-layout-page-title .team-toggler {
  margin-top: -5px;
  margin-bottom: 0px;
}

.full-width {
  max-width: 100%;
  width: 100% !important;
}

.multiline {
  white-space: pre-line;
}

.pull-right {
  float: right;
}

/**
* TYPOGRAPHY
*
* Typographic Scale: 10, 12, 14, 16, 19, 26, 31, 42, 51
*/
* {
  font-weight: 400;
  font-style: normal;
}

a[href],
a[role=button] {
  cursor: pointer;
}

.grayed {
  color: #bdbdbd !important;
}

.hot-key-label {
  color: #bfcedc;
  display: inline-block;
  font-size: 12px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.hot-key-label:not(.transparent) {
  text-transform: lowercase;
  color: #424242;
  background-color: #eef5fb;
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  padding: 0.25rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/**
 * LINKS / BUTTONS
 */
a {
  /* CSS transitions */
  -webkit-transition: opacity, color, background-color 0.2s ease;
  -moz-transition: opacity, color, background-color 0.2s ease;
  transition: opacity, color, background-color 0.2s ease;
}
a.disabled {
  color: rgba(33, 33, 33, 0.26);
  pointer-events: none;
}

kbd {
  color: #242729;
  text-shadow: 0 1px 0 #FFF;
  background-color: #e1e3e5;
  border: 1px solid #adb3b9;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #FFF inset;
  display: inline-block;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-family: Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1.4;
  border-radius: 3px;
  white-space: nowrap;
}

/**
 * FORMS
 */
label[for] {
  cursor: pointer;
}

form {
  margin-bottom: 0;
}
form label {
  color: #1a2d3e;
  font-weight: 500;
}
form small {
  margin-bottom: 22px;
}

label {
  padding-top: 0;
  margin-bottom: 0;
  color: #1a2d3e;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 22px;
  display: block;
}
label span.mat-checkbox-label {
  text-transform: capitalize;
}

input[type=checkbox],
input[type=radio] {
  cursor: pointer;
}

/**
 * This is styling for lazy load buttons
 */
.mgd-layout-lazy-load-button {
  background-color: #e3ebf7;
  color: #091a28;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
  padding: 10px;
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* CSS transitions */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.mgd-layout-lazy-load-button:hover {
  background-color: #bfcedc;
  cursor: pointer;
}

/**
 * This will style a section of the application to hold content of the page 
 * i.e.
 * <header class="mgd-layout-page-title>
 * </header>
 * <div class="mgd-layout-page-conntent">
 * ...
 * </div>
 */
.mgd-layout-page-content {
  margin: 1.5rem;
  position: relative;
  width: calc(100% - 2 * 1.5rem);
}

/**
* This is a wrapper for content within a mgd-layout-page-content
* 
* <div class="mgd-layout-page-content">
*   <div class="mgd-layout-page-content-section">
*      ...
*   </div>
* </div>
*/
.mgd-layout-page-content-section, .mgd-list-no-filter, .mgd-list-with-filter, .mgd-layout-page-content-section-flat-top, .mgd-layout-page-content-section-filter, .mgd-list-filter {
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  display: table;
  width: 100%;
  padding: 1rem;
  -webkit-box-shadow: 0 4px 1px -2px rgba(0, 0, 0, 0.2), 0 3px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 1px -2px rgba(0, 0, 0, 0.2), 0 3px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.mgd-layout-page-content-section.with-filter, .with-filter.mgd-list-no-filter, .with-filter.mgd-list-with-filter, .with-filter.mgd-layout-page-content-section-flat-top, .with-filter.mgd-layout-page-content-section-filter, .with-filter.mgd-list-filter {
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
* This is a wrapper for filter content within a mgd-layout-page-content
* It should be used with .mgd-layout-page-content-section-flat-top
*
* <div class="mgd-layout-page-content">
*   <div class="mgd-layout-page-content-section-filter">
*      ...
*   </div>
*   <div class="mgd-layout-page-content-section-flat-top">
*     ...
*   </div>
* </div>
*/
.mgd-layout-page-content-section-filter, .mgd-list-filter {
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #eef3fa;
  border-bottom: none;
  padding: 25px 20px;
}

/**
* This is a wrapper for  content within a mgd-layout-page-content
* It should be used with .mgd-layout-page-content-section-filter
*
* <div class="mgd-layout-page-content">
*   <div class="mgd-layout-page-content-section-filter">
*      ...
*   </div>
*   <div class="mgd-layout-page-content-section-flat-top">
*     ...
*   </div>
* </div>
*/
.mgd-layout-page-content-section-flat-top {
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
* This is a wrapper for Action Buttons in a Form or view field without a top border
*/
.mgd-layout-action-buttons-section-no-border, .mgd-layout-action-buttons-section, mat-card-actions {
  margin: 5px 1.5rem 1.5rem;
  padding-top: 20px;
  justify-content: flex-end;
}

/**
* This is a wrapper for Action Buttons in a Form or view field
*/
.mgd-layout-action-buttons-section, mat-card-actions {
  border-top: 1px solid #eeeeee;
}

.text-center {
  /* Also align flex items */
  justify-content: center;
  text-align: center;
}

.text-left {
  /* Also align flex items */
  justify-content: left;
  text-align: left;
}

.text-right {
  /* Also align flex items */
  justify-content: right;
  text-align: right;
}

.flex-18 {
  flex-basis: 18px;
}

.flex-40 {
  flex-basis: 40px;
}

.flex-50 {
  flex-basis: 50px;
}

.flex-70 {
  flex-basis: 70px;
}

.flex-80 {
  flex-basis: 80px;
}

.flex-90 {
  flex-basis: 90px;
}

.flex-100 {
  flex-basis: 100px;
}

.flex-125 {
  flex-basis: 125px;
}

.flex-130 {
  flex-basis: 130px;
}

.flex-140 {
  flex-basis: 140px;
}

.flex-150 {
  flex-basis: 150px;
}

.flex-175 {
  flex-basis: 175px;
}

.flex-200 {
  flex-basis: 200px;
}

.flex-250 {
  flex-basis: 250px;
}

.flex-300 {
  flex-basis: 300px;
}

.flex-320 {
  flex-basis: 320px;
}

.flex-400 {
  flex-basis: 400px;
}

.flex-23-5 {
  flex-basis: 23.5%;
}

.flex-8-5 {
  flex-basis: 8.5%;
}

.flex-50-5 {
  flex-basis: 50.5%;
}

.flex-16-5 {
  flex-basis: 16.5%;
}

.mat-mdc-snack-bar-container.snack-bar-error {
  --mdc-snackbar-container-color: #bf000f;
  --mdc-snackbar-supporting-text-color: #f8fafd;
  --mat-mdc-snack-bar-button-color: #f8fafd;
}
.mat-mdc-snack-bar-container.snack-bar-warning {
  --mdc-snackbar-container-color: #ff6f00;
  --mdc-snackbar-supporting-text-color: #f8fafd;
  --mat-mdc-snack-bar-button-color: #f8fafd;
}
.mat-mdc-snack-bar-container.snack-bar-success {
  --mdc-snackbar-container-color: #1a2d3e;
  --mdc-snackbar-supporting-text-color: #f8fafd;
}
.mat-mdc-snack-bar-container .mat-mdc-simple-snack-bar {
  font-weight: 300 !important;
}
.mat-mdc-snack-bar-container .mat-mdc-snack-bar-action {
  color: #f8fafd !important;
  font-weight: 700 !important;
}

button.mat-mdc-raised-button {
  background-color: #f6f9fc;
  color: #091a28;
}
button.mat-mdc-raised-button:disabled {
  background-color: rgba(33, 33, 33, 0.12) !important;
  color: rgba(33, 33, 33, 0.26) !important;
}
button.mat-mdc-raised-button[type=submit], button.mat-mdc-raised-button.main-action-button {
  background-color: #3872bb;
  color: #f8fafd;
}
button.mat-mdc-raised-button.list-action-button {
  background-color: #091a28;
  color: #f8fafd;
}
button.mat-mdc-raised-button.warn-action-button {
  background-color: #fd2b2b;
  color: #f8fafd;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix {
  padding-top: 12px;
}

.mat-mdc-form-field-focus-overlay, .mdc-text-field--filled {
  background-color: transparent !important;
}

.mat-mdc-form-field-label:not(.mat-mdc-form-field-empty) {
  font-weight: 500;
  color: #3162a9;
}

textarea.mat-mdc-input-element {
  border-radius: 5px;
  max-width: calc(100% + 6px);
  padding: 3px !important;
  width: calc(100% + 6px);
}

.mat-mdc-form-field-icon-prefix .mat-mdc-form-field-icon-suffix {
  top: 8px;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
  width: auto !important;
}
.mat-mdc-form-field-infix .mat-mdc-floating-label {
  z-index: -1;
  padding-top: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mat-mdc-form-field-infix input {
  background: none;
  border: none;
  font: inherit;
  outline: none;
  padding: 0;
  width: 100%;
}

mat-option[hidden] {
  display: none;
}
mat-option .mdc-list-item__primary-text {
  font-size: 14px !important;
  display: block;
}

.multi-selector-filter-panel .mat-select-filter-input {
  width: 100%;
}

.mat-select-filter-input {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  display: block;
  line-height: 40px;
  padding: 0 calc(1.5rem / 2);
  width: 100%;
}

.mat-mdc-select-panel {
  max-height: 300px !important;
  position: relative !important;
  padding: 0 !important;
}

.mat-mdc-tab-link {
  min-width: 100px !important;
  color: #091a28;
}

.mat-mdc-tab-nav-bar {
  background-color: #f6f9fc;
}

.mat-toolbar {
  background-color: #e3ebf7 !important;
}

.mat-drawer {
  background-color: #091a28 !important;
}
.mat-drawer .mat-mdc-list-item {
  position: relative;
  height: 56px !important;
}
.mat-drawer .mat-mdc-list-item .current-route mat-icon {
  color: #be2f42;
}
.mat-drawer .mat-mdc-list-item a {
  color: #e3ebf7;
  text-decoration: none;
  padding: 0 2rem;
}
.mat-drawer .mat-mdc-list-item a span {
  margin-left: 4px;
}
.mat-drawer .mat-mdc-list-item a * {
  vertical-align: middle;
}
.mat-drawer .current-route {
  color: #3872bb !important;
}
.mat-drawer .version {
  color: #96c7ed !important;
}

.mat-mdc-dialog-container {
  border-radius: 5px !important;
  padding: 0 !important;
}
.mat-mdc-dialog-container .mdc-dialog__title::before {
  display: none;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  background-color: #eef3fa;
  margin: 0 !important;
  padding: 0 !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div {
  display: flex;
  flex-basis: 100%;
  padding: calc(1.5rem / 2) 1.5rem 0;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div:first-child {
  padding-top: 1.5rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div:last-child {
  padding-bottom: 1.5rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div.actions {
  text-align: right;
  align-items: center;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div.actions > * {
  display: inline-block;
  margin: 0 0.5rem 0 0 !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div.actions > *:first-child {
  margin-left: auto !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div.actions > *:last-child {
  margin-right: 0 !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions > div.messages .warning mat-icon {
  color: #ffea00;
  font-size: 16px;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  padding: 1.5rem !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  position: relative;
  background-color: #091a28;
  color: #f8fafd !important;
  margin: 0;
  padding: 1.5rem;
  /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
  */
  /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
  */
}
.mat-mdc-dialog-container .mat-mdc-dialog-title h2 {
  position: relative;
  margin: 0;
  z-index: 0;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title h2 > .mat-icon {
  vertical-align: middle;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title .mat-dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title .shortcut-tip {
  position: absolute;
  top: 0;
  right: calc(40px + 0.5rem);
  line-height: 40px;
  z-index: 1;
  font-size: 16px;
}

.cdk-overlay-container .action-button-menu-panel {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.cdk-overlay-container .action-button-menu-panel button {
  background-color: #091a28;
  color: #f8fafd;
}
.cdk-overlay-container .action-button-menu-panel button.disabled {
  background-color: rgba(33, 33, 33, 0.12);
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel {
  min-width: 0 !important;
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding-top: 0;
  padding-bottom: 0;
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  width: var(--mdc-icon-button-state-layer-size, 48px);
  background-color: #091a28;
  margin-top: 10px;
  padding: 0;
  text-align: center;
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.disabled {
  background-color: rgba(33, 33, 33, 0.12);
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
  background-color: transparent;
  color: #f8fafd;
  margin: 0;
}
.cdk-overlay-container .action-button-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item :not([disabled]) .mat-icon:not([color]) {
  color: #091a28;
}

.mat-mdc-list .mat-mdc-list-item {
  overflow: visible;
}

.mat-mdc-tooltip {
  color: #f8fafd !important;
  text-transform: uppercase;
}
.mat-mdc-tooltip.wide {
  max-width: 700px;
}

mat-button-toggle .mat-button-toggle-label-content {
  color: #091a28;
  text-transform: none;
}
mat-button-toggle.mat-button-toggle-checked {
  background-color: #3872bb !important;
}
mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content {
  color: #f8fafd;
}

mat-card {
  border-radius: 5px !important;
}
mat-card .element-style-title {
  background-color: #091a28;
  color: #f8fafd;
}

mat-card-actions {
  justify-content: flex-end;
  padding: 20px 0 0 !important;
}

mat-cell,
mat-header-cell {
  overflow: visible !important;
}

mat-chip mat-icon {
  font-size: 14px !important;
  height: 14px !important;
  width: 14px !important;
}
mat-chip:not(.mat-mdc-basic-chip) {
  background-color: #425e78 !important;
}
mat-chip .mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}
mat-chip .mat-mdc-chip-action-label, mat-chip .mat-mdc-chip-remove {
  color: #f8fafd !important;
}

mat-form-field {
  max-width: 100% !important;
  width: 100%;
}

mat-table {
  font-family: Roboto, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0.5rem;
}

.mat-accent .mat-mdc-tab-link {
  color: #f8fafd;
  font-weight: 400;
}
.mat-accent .mat-mdc-tab-link:active, .mat-accent .mat-mdc-tab-link:focus, .mat-accent .mat-mdc-tab-link:hover {
  color: #3162a9;
}

.mat-mdc-icon-button {
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.mat-mdc-icon-button:not(.mat-dialog-close) .mat-mdc-icon-button :not(.mat-mdc-menu-item) {
  width: 36px !important;
  height: 36px !important;
}
.mat-mdc-icon-button.mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: none;
}

.mat-mdc-list-item .mat-icon :not(.mat-mdc-nav-list) {
  width: 19px;
  height: 19px;
}

.mat-mdc-list-item .mdc-list-item__content {
  padding: 0 16px;
}
.mat-mdc-list-item .mdc-list-item__primary-text {
  align-items: center;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-label,
.mat-mdc-paginator .mat-mdc-paginator-range-label,
.mat-mdc-paginator .mat-select-value,
.mat-mdc-paginator .mat-select-arrow {
  color: #091a28;
}
.mat-mdc-paginator .mat-paginator-decrement,
.mat-mdc-paginator .mat-paginator-increment {
  border-top-color: #3872bb;
  border-right-color: #3872bb;
}

.mat-mdc-progress-spinner {
  position: relative;
  top: 1px;
  margin: 0 auto;
}

.mat-mdc-slide-toggle {
  height: auto !important;
  margin: 0px !important;
}
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #3872bb !important;
}
.mat-mdc-slide-toggle:not(.mat-checked) .mat-slide-toggle-bar {
  background-color: #f8fafd !important;
}

.mat-mdc-table {
  background-color: transparent !important;
}

.mat-mdc-header-row,
.mat-mdc-row {
  border-bottom-width: 1px;
  border-bottom-color: #bdbdbd;
}
.mat-mdc-header-row:last-child,
.mat-mdc-row:last-child {
  border: none;
}
.mat-mdc-header-row.clickable:hover,
.mat-mdc-row.clickable:hover {
  background-color: #eef5fb;
  cursor: pointer;
  /* CSS transitions */
  -webkit-transition: background-color 300ms ease 0.2s ease;
  -moz-transition: background-color 300ms ease 0.2s ease;
  transition: background-color 300ms ease 0.2s ease;
}
.mat-mdc-header-row.highlighted,
.mat-mdc-row.highlighted {
  background-color: #eef5fb;
}
.mat-mdc-header-row.highlighted:hover,
.mat-mdc-row.highlighted:hover {
  background-color: #ecf3fb;
}
.mat-mdc-header-row.disabled,
.mat-mdc-row.disabled {
  background-color: rgba(0, 0, 0, 0.03);
}
.mat-mdc-header-row.disabled *,
.mat-mdc-row.disabled * {
  color: #bdbdbd;
}
.mat-mdc-header-row.warning,
.mat-mdc-row.warning {
  border: 2px solid #ffea00;
}
.mat-mdc-header-row.error,
.mat-mdc-row.error {
  border: 2px solid #fd2b2b;
}
.mat-mdc-header-row .mat-mdc-header-cell,
.mat-mdc-row .mat-mdc-header-cell {
  color: #3162a9;
}
.mat-mdc-header-row .mat-mdc-cell,
.mat-mdc-header-row .mat-mdc-header-cell,
.mat-mdc-row .mat-mdc-cell,
.mat-mdc-row .mat-mdc-header-cell {
  font-size: 12px !important;
  font-weight: normal;
  padding: 0 2px !important;
}
.mat-mdc-header-row .mat-mdc-cell > mat-icon,
.mat-mdc-header-row .mat-mdc-header-cell > mat-icon,
.mat-mdc-row .mat-mdc-cell > mat-icon,
.mat-mdc-row .mat-mdc-header-cell > mat-icon {
  font-size: 20px;
}
.mat-mdc-header-row .mat-mdc-cell .hot-key-label,
.mat-mdc-header-row .mat-mdc-header-cell .hot-key-label,
.mat-mdc-row .mat-mdc-cell .hot-key-label,
.mat-mdc-row .mat-mdc-header-cell .hot-key-label {
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.mat-mdc-header-row .mat-mdc-cell .cdk-column-info,
.mat-mdc-header-row .mat-mdc-header-cell .cdk-column-info,
.mat-mdc-row .mat-mdc-cell .cdk-column-info,
.mat-mdc-row .mat-mdc-header-cell .cdk-column-info {
  position: relative;
  color: #3162a9;
  max-width: 0;
}
.mat-mdc-header-row .mat-mdc-cell .cdk-column-info mat-icon,
.mat-mdc-header-row .mat-mdc-header-cell .cdk-column-info mat-icon,
.mat-mdc-row .mat-mdc-cell .cdk-column-info mat-icon,
.mat-mdc-row .mat-mdc-header-cell .cdk-column-info mat-icon {
  font-size: 20px !important;
  height: 20px;
  margin-left: -24px;
  margin-top: 3px;
  width: 20px;
}
.mat-mdc-header-row .mat-mdc-cell mat-icon.warning,
.mat-mdc-header-row .mat-mdc-header-cell mat-icon.warning,
.mat-mdc-row .mat-mdc-cell mat-icon.warning,
.mat-mdc-row .mat-mdc-header-cell mat-icon.warning {
  color: yellow;
}
.mat-mdc-header-row .mat-mdc-cell mat-icon.error,
.mat-mdc-header-row .mat-mdc-header-cell mat-icon.error,
.mat-mdc-row .mat-mdc-cell mat-icon.error,
.mat-mdc-row .mat-mdc-header-cell mat-icon.error {
  color: #fd2b2b;
}

.mgd-list-empty-state-container {
  background-color: #eef3fa;
  text-align: center;
  padding: 50px 0 40px;
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.mgd-list-empty-state-container p {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #091a28;
}

.mgd-list-filter .mgd-list-actions {
  flex-basis: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}

/**
* Wrapper for mat-table for a list with a .mgd-list-filter section above it
*/
.mgd-list-with-filter {
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
* wrapper for mat-table for a list without a .mgd-list-filter section
*/
html {
  min-width: 1300px;
}

[hidden] {
  display: none !important;
}

/**
 * FORM COMPONENTS
 */
.operator-toggle-button[disabled] .operator-bottom,
.operator-toggle-button[disabled] .operator-top {
  border-bottom: 1px solid #eeeeee;
}
.operator-toggle-button .operator-bottom,
.operator-toggle-button .operator-top {
  border-bottom: 1px solid #bdbdbd;
  display: block;
  height: 1px;
  margin: 5px auto;
  width: 10px;
  -webkit-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}
.operator-toggle-button.eq .operator-bottom {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.operator-toggle-button.eq .operator-top {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.operator-toggle-button.gte .operator-bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.operator-toggle-button.gte .operator-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.operator-toggle-button.lte .operator-bottom {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.operator-toggle-button.lte .operator-top {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/**
 * PRELOAD ANIMATION
 */
.preloader-container {
  background-color: #eef3fa;
  min-height: 250px;
  padding-top: 85px;
  /* Rounds corners */
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.loader,
.loader::after,
.loader::before {
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  position: relative;
  color: #eef3fa;
  font-size: 10px;
  margin: 0 auto;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader::after, .loader::before {
  position: absolute;
  top: 0;
  content: "";
}
.loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader.deal-count-loader {
  top: 50%;
  color: #bdbdbd;
  font-size: 2px;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@page {
  size: landscape;
}
@media print {
  html,
  body {
    min-width: 100% !important;
    height: auto !important;
  }
  section {
    border: 0 !important;
    margin: 50px 0 0 0 !important;
  }
  .secondary-nav:not(.active) {
    display: none;
  }
  .site-menu-component .module-site-navigation {
    display: none !important;
  }
  .admin-component .admin-main-navigation {
    display: none !important;
  }
  .admin-component .main-content-container {
    padding: 0 !important;
  }
  .admin-component .mgd-layout-main-content-wrapper {
    padding-top: 0 !important;
  }
  .team-dashboard-component .dashboard-charts-container,
  .team-dashboard-component .secondary-nav-wrapper,
  .team-dashboard-component .prefill-filters {
    display: none !important;
  }
  .team-dashboard-component .headings {
    padding: 20px 0 0 !important;
  }
  .task-list-component .mgd-list-filter-search,
  .task-list-component .lazy-load-container {
    display: none !important;
  }
  .task-view-component .column-task-actions-wrapper {
    display: none !important;
  }
  .task-view-component .task-name {
    padding-right: 25px;
  }
  .task-view-component .task-name .related-task-count {
    right: 0px;
    position: absolute;
    top: 0px;
  }
}
/**
 * Listed Items Table
 */
.product-image {
  max-height: 40px;
  max-width: 100%;
  display: block;
}

.item-row {
  border-bottom: 1px solid #bdbdbd;
  padding: 5px 0px;
}
.item-row:nth-child(even) {
  background-color: #f6f9fc;
}
.item-row.single-deal-row:hover {
  cursor: pointer;
}
.item-row.item-row-header {
  color: #3162a9;
  background-color: white;
  font-weight: 500;
  overflow: hidden;
}
.item-row.selected-row {
  background-color: #eef3fa;
}

.item-column {
  float: left;
  padding: 5px 1%;
  word-wrap: break-word;
}

*[data-hide] {
  display: none !important;
}

@media not screen and (max-width: 599px) {
  *[data-show-xs] {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  *[data-hide-xs] {
    display: none !important;
  }
}

@media not screen and (min-width: 600px) and (max-width: 959px) {
  *[data-show-sm] {
    display: none !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-hide-sm] {
    display: none !important;
  }
}

@media not screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-show-md] {
    display: none !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-hide-md] {
    display: none !important;
  }
}

@media not screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-show-lg] {
    display: none !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-hide-lg] {
    display: none !important;
  }
}

@media not screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-show-xl] {
    display: none !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-hide-xl] {
    display: none !important;
  }
}

@media not screen and (max-width: 599px) {
  *[data-show-lt-sm] {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  *[data-hide-lt-sm] {
    display: none !important;
  }
}

@media not screen and (max-width: 959px) {
  *[data-show-lt-md] {
    display: none !important;
  }
}

@media screen and (max-width: 959px) {
  *[data-hide-lt-md] {
    display: none !important;
  }
}

@media not screen and (max-width: 1279px) {
  *[data-show-lt-lg] {
    display: none !important;
  }
}

@media screen and (max-width: 1279px) {
  *[data-hide-lt-lg] {
    display: none !important;
  }
}

@media not screen and (max-width: 1919px) {
  *[data-show-lt-xl] {
    display: none !important;
  }
}

@media screen and (max-width: 1919px) {
  *[data-hide-lt-xl] {
    display: none !important;
  }
}

@media not screen and (min-width: 600px) {
  *[data-show-gt-xs] {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  *[data-hide-gt-xs] {
    display: none !important;
  }
}

@media not screen and (min-width: 960px) {
  *[data-show-gt-sm] {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  *[data-hide-gt-sm] {
    display: none !important;
  }
}

@media not screen and (min-width: 1280px) {
  *[data-show-gt-md] {
    display: none !important;
  }
}

@media screen and (min-width: 1280px) {
  *[data-hide-gt-md] {
    display: none !important;
  }
}

@media not screen and (min-width: 1920px) {
  *[data-show-gt-lg] {
    display: none !important;
  }
}

@media screen and (min-width: 1920px) {
  *[data-hide-gt-lg] {
    display: none !important;
  }
}

*[data-layout^=row] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
*[data-layout^=row][data-layout~=reverse] {
  flex-direction: row-reverse;
}

*[data-layout^=column] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
*[data-layout^=column][data-layout~=reverse] {
  flex-direction: column-reverse;
}

*[data-layout~=wrap] {
  flex-wrap: wrap;
}

*[data-layout-align][data-layout-align^=start] {
  justify-content: flex-start;
}
*[data-layout-align][data-layout-align^=end] {
  justify-content: flex-end;
}
*[data-layout-align][data-layout-align^=center] {
  justify-content: center;
}
*[data-layout-align][data-layout-align^=space-between] {
  justify-content: space-between;
}
*[data-layout-align][data-layout-align^=space-around] {
  justify-content: space-around;
}
*[data-layout-align][data-layout-align^=space-evenly] {
  justify-content: space-evenly;
}
*[data-layout-align][data-layout-align$=" start"] {
  align-items: flex-start;
}
*[data-layout-align][data-layout-align$=" end"] {
  align-items: flex-end;
}
*[data-layout-align][data-layout-align$=" center"] {
  align-items: center;
}
*[data-layout-align][data-layout-align$=" baseline"] {
  align-items: baseline;
}
*[data-layout-align][data-layout-align$=" stretch"] {
  align-items: stretch;
}

*[data-layout-align]:not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

*[data-flex] {
  flex: 1 1 0;
}

*[data-flex=auto] {
  flex-basis: 100%;
}

*[data-flex=grow] {
  flex: 1 1 100%;
}

*[data-flex=initial] {
  flex: 0 1 auto;
}

*[data-flex=none] {
  flex: 0 0 auto;
}

*[data-flex=nogrow] {
  flex: 0 1 auto;
}

*[data-flex=noshrink] {
  flex: 1 0 auto;
}

*[data-flex-fill] {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

*:has(> [data-flex], > [data-flex-fill]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

*[data-flex-align=start] {
  align-self: flex-start;
}

*[data-flex-align=center] {
  align-self: center;
}

*[data-flex-align=end] {
  align-self: flex-end;
}

*[data-flex-align=baseline] {
  align-self: baseline;
}

*[data-flex-align=stretch] {
  align-self: stretch;
}

@media screen and (max-width: 599px) {
  *[data-layout-xs^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-xs^=row][data-layout-xs~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-xs^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-xs^=column][data-layout-xs~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-xs~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-layout-sm^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-sm^=row][data-layout-sm~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-sm^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-sm^=column][data-layout-sm~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-sm~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-layout-md^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-md^=row][data-layout-md~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-md^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-md^=column][data-layout-md~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-md~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-layout-lg^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-lg^=row][data-layout-lg~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-lg^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-lg^=column][data-layout-lg~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-lg~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-layout-xl^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-xl^=row][data-layout-xl~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-xl^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-xl^=column][data-layout-xl~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-xl~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 599px) {
  *[data-layout-lt-sm^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-sm^=row][data-layout-lt-sm~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-lt-sm^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-sm^=column][data-layout-lt-sm~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-lt-sm~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 959px) {
  *[data-layout-lt-md^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-md^=row][data-layout-lt-md~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-lt-md^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-md^=column][data-layout-lt-md~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-lt-md~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1279px) {
  *[data-layout-lt-lg^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-lg^=row][data-layout-lt-lg~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-lt-lg^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-lg^=column][data-layout-lt-lg~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-lt-lg~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1919px) {
  *[data-layout-lt-xl^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-xl^=row][data-layout-lt-xl~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-lt-xl^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-lt-xl^=column][data-layout-lt-xl~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-lt-xl~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 600px) {
  *[data-layout-gt-xs^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-xs^=row][data-layout-gt-xs~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-gt-xs^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-xs^=column][data-layout-gt-xs~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-gt-xs~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 960px) {
  *[data-layout-gt-sm^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-sm^=row][data-layout-gt-sm~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-gt-sm^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-sm^=column][data-layout-gt-sm~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-gt-sm~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) {
  *[data-layout-gt-md^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-md^=row][data-layout-gt-md~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-gt-md^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-md^=column][data-layout-gt-md~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-gt-md~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1920px) {
  *[data-layout-gt-lg^=row] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-lg^=row][data-layout-gt-lg~=reverse] {
    flex-direction: row-reverse;
  }
  *[data-layout-gt-lg^=column] {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  *[data-layout-gt-lg^=column][data-layout-gt-lg~=reverse] {
    flex-direction: column-reverse;
  }
  *[data-layout-gt-lg~=wrap] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 599px) {
  *[data-layout-align-xs][data-layout-align-xs^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-xs][data-layout-align-xs^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-xs][data-layout-align-xs^=center] {
    justify-content: center;
  }
  *[data-layout-align-xs][data-layout-align-xs^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-xs][data-layout-align-xs^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-xs][data-layout-align-xs^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-xs][data-layout-align-xs$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-xs][data-layout-align-xs$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-xs][data-layout-align-xs$=" center"] {
    align-items: center;
  }
  *[data-layout-align-xs][data-layout-align-xs$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-xs][data-layout-align-xs$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-layout-align-sm][data-layout-align-sm^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-sm][data-layout-align-sm^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-sm][data-layout-align-sm^=center] {
    justify-content: center;
  }
  *[data-layout-align-sm][data-layout-align-sm^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-sm][data-layout-align-sm^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-sm][data-layout-align-sm^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-sm][data-layout-align-sm$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-sm][data-layout-align-sm$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-sm][data-layout-align-sm$=" center"] {
    align-items: center;
  }
  *[data-layout-align-sm][data-layout-align-sm$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-sm][data-layout-align-sm$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-layout-align-md][data-layout-align-md^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-md][data-layout-align-md^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-md][data-layout-align-md^=center] {
    justify-content: center;
  }
  *[data-layout-align-md][data-layout-align-md^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-md][data-layout-align-md^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-md][data-layout-align-md^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-md][data-layout-align-md$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-md][data-layout-align-md$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-md][data-layout-align-md$=" center"] {
    align-items: center;
  }
  *[data-layout-align-md][data-layout-align-md$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-md][data-layout-align-md$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-layout-align-lg][data-layout-align-lg^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-lg][data-layout-align-lg^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-lg][data-layout-align-lg^=center] {
    justify-content: center;
  }
  *[data-layout-align-lg][data-layout-align-lg^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-lg][data-layout-align-lg^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-lg][data-layout-align-lg^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-lg][data-layout-align-lg$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-lg][data-layout-align-lg$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-lg][data-layout-align-lg$=" center"] {
    align-items: center;
  }
  *[data-layout-align-lg][data-layout-align-lg$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-lg][data-layout-align-lg$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-layout-align-xl][data-layout-align-xl^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-xl][data-layout-align-xl^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-xl][data-layout-align-xl^=center] {
    justify-content: center;
  }
  *[data-layout-align-xl][data-layout-align-xl^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-xl][data-layout-align-xl^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-xl][data-layout-align-xl^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-xl][data-layout-align-xl$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-xl][data-layout-align-xl$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-xl][data-layout-align-xl$=" center"] {
    align-items: center;
  }
  *[data-layout-align-xl][data-layout-align-xl$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-xl][data-layout-align-xl$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (max-width: 599px) {
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=center] {
    justify-content: center;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm$=" center"] {
    align-items: center;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-lt-sm][data-layout-align-lt-sm$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (max-width: 959px) {
  *[data-layout-align-lt-md][data-layout-align-lt-md^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md^=center] {
    justify-content: center;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md$=" center"] {
    align-items: center;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-lt-md][data-layout-align-lt-md$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (max-width: 1279px) {
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=center] {
    justify-content: center;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg$=" center"] {
    align-items: center;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-lt-lg][data-layout-align-lt-lg$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (max-width: 1919px) {
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=center] {
    justify-content: center;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl$=" center"] {
    align-items: center;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-lt-xl][data-layout-align-lt-xl$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 600px) {
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=center] {
    justify-content: center;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs$=" center"] {
    align-items: center;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-gt-xs][data-layout-align-gt-xs$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 960px) {
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=center] {
    justify-content: center;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm$=" center"] {
    align-items: center;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-gt-sm][data-layout-align-gt-sm$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 1280px) {
  *[data-layout-align-gt-md][data-layout-align-gt-md^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md^=center] {
    justify-content: center;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md$=" center"] {
    align-items: center;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-gt-md][data-layout-align-gt-md$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (min-width: 1920px) {
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=start] {
    justify-content: flex-start;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=end] {
    justify-content: flex-end;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=center] {
    justify-content: center;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=space-between] {
    justify-content: space-between;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=space-around] {
    justify-content: space-around;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg^=space-evenly] {
    justify-content: space-evenly;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg$=" start"] {
    align-items: flex-start;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg$=" end"] {
    align-items: flex-end;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg$=" center"] {
    align-items: center;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg$=" baseline"] {
    align-items: baseline;
  }
  *[data-layout-align-gt-lg][data-layout-align-gt-lg$=" stretch"] {
    align-items: stretch;
  }
}
@media screen and (max-width: 599px) {
  *[data-flex-xs] {
    flex: 1 1 0;
  }
  *[data-flex-xs=auto] {
    flex-basis: 100%;
  }
  *[data-flex-xs=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-xs=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-xs=none] {
    flex: 0 0 auto;
  }
  *[data-flex-xs=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-xs=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-xs] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-xs], > [data-flex-fill-xs]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-flex-sm] {
    flex: 1 1 0;
  }
  *[data-flex-sm=auto] {
    flex-basis: 100%;
  }
  *[data-flex-sm=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-sm=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-sm=none] {
    flex: 0 0 auto;
  }
  *[data-flex-sm=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-sm=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-sm] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-sm], > [data-flex-fill-sm]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-flex-md] {
    flex: 1 1 0;
  }
  *[data-flex-md=auto] {
    flex-basis: 100%;
  }
  *[data-flex-md=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-md=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-md=none] {
    flex: 0 0 auto;
  }
  *[data-flex-md=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-md=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-md] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-md], > [data-flex-fill-md]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-flex-lg] {
    flex: 1 1 0;
  }
  *[data-flex-lg=auto] {
    flex-basis: 100%;
  }
  *[data-flex-lg=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-lg=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-lg=none] {
    flex: 0 0 auto;
  }
  *[data-flex-lg=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-lg=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-lg] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-lg], > [data-flex-fill-lg]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-flex-xl] {
    flex: 1 1 0;
  }
  *[data-flex-xl=auto] {
    flex-basis: 100%;
  }
  *[data-flex-xl=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-xl=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-xl=none] {
    flex: 0 0 auto;
  }
  *[data-flex-xl=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-xl=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-xl] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-xl], > [data-flex-fill-xl]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  *[data-flex-lt-sm] {
    flex: 1 1 0;
  }
  *[data-flex-lt-sm=auto] {
    flex-basis: 100%;
  }
  *[data-flex-lt-sm=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-lt-sm=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-sm=none] {
    flex: 0 0 auto;
  }
  *[data-flex-lt-sm=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-sm=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-lt-sm] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-lt-sm], > [data-flex-fill-lt-sm]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (max-width: 959px) {
  *[data-flex-lt-md] {
    flex: 1 1 0;
  }
  *[data-flex-lt-md=auto] {
    flex-basis: 100%;
  }
  *[data-flex-lt-md=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-lt-md=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-md=none] {
    flex: 0 0 auto;
  }
  *[data-flex-lt-md=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-md=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-lt-md] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-lt-md], > [data-flex-fill-lt-md]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1279px) {
  *[data-flex-lt-lg] {
    flex: 1 1 0;
  }
  *[data-flex-lt-lg=auto] {
    flex-basis: 100%;
  }
  *[data-flex-lt-lg=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-lt-lg=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-lg=none] {
    flex: 0 0 auto;
  }
  *[data-flex-lt-lg=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-lg=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-lt-lg] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-lt-lg], > [data-flex-fill-lt-lg]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1919px) {
  *[data-flex-lt-xl] {
    flex: 1 1 0;
  }
  *[data-flex-lt-xl=auto] {
    flex-basis: 100%;
  }
  *[data-flex-lt-xl=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-lt-xl=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-xl=none] {
    flex: 0 0 auto;
  }
  *[data-flex-lt-xl=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-lt-xl=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-lt-xl] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-lt-xl], > [data-flex-fill-lt-xl]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 600px) {
  *[data-flex-gt-xs] {
    flex: 1 1 0;
  }
  *[data-flex-gt-xs=auto] {
    flex-basis: 100%;
  }
  *[data-flex-gt-xs=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-gt-xs=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-xs=none] {
    flex: 0 0 auto;
  }
  *[data-flex-gt-xs=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-xs=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-gt-xs] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-gt-xs], > [data-flex-fill-gt-xs]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 960px) {
  *[data-flex-gt-sm] {
    flex: 1 1 0;
  }
  *[data-flex-gt-sm=auto] {
    flex-basis: 100%;
  }
  *[data-flex-gt-sm=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-gt-sm=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-sm=none] {
    flex: 0 0 auto;
  }
  *[data-flex-gt-sm=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-sm=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-gt-sm] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-gt-sm], > [data-flex-fill-gt-sm]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1280px) {
  *[data-flex-gt-md] {
    flex: 1 1 0;
  }
  *[data-flex-gt-md=auto] {
    flex-basis: 100%;
  }
  *[data-flex-gt-md=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-gt-md=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-md=none] {
    flex: 0 0 auto;
  }
  *[data-flex-gt-md=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-md=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-gt-md] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-gt-md], > [data-flex-fill-gt-md]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1920px) {
  *[data-flex-gt-lg] {
    flex: 1 1 0;
  }
  *[data-flex-gt-lg=auto] {
    flex-basis: 100%;
  }
  *[data-flex-gt-lg=grow] {
    flex: 1 1 100%;
  }
  *[data-flex-gt-lg=initial] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-lg=none] {
    flex: 0 0 auto;
  }
  *[data-flex-gt-lg=nogrow] {
    flex: 0 1 auto;
  }
  *[data-flex-gt-lg=noshrink] {
    flex: 1 0 auto;
  }
  *[data-flex-fill-gt-lg] {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}
*:has(> [data-flex-gt-lg], > [data-flex-fill-gt-lg]):not([data-layout], [data-layout-xs], [data-layout-sm], [data-layout-md], [data-layout-lg], [data-layout-xl], [data-layout-lt-sm], [data-layout-lt-md], [data-layout-lt-lg], [data-layout-lt-xl], [data-layout-gt-xs], [data-layout-gt-sm], [data-layout-gt-md], [data-layout-gt-lg]) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  *[data-flex-align-xs=start] {
    align-self: flex-start;
  }
  *[data-flex-align-xs=center] {
    align-self: center;
  }
  *[data-flex-align-xs=end] {
    align-self: flex-end;
  }
  *[data-flex-align-xs=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-xs=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-flex-align-sm=start] {
    align-self: flex-start;
  }
  *[data-flex-align-sm=center] {
    align-self: center;
  }
  *[data-flex-align-sm=end] {
    align-self: flex-end;
  }
  *[data-flex-align-sm=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-sm=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-flex-align-md=start] {
    align-self: flex-start;
  }
  *[data-flex-align-md=center] {
    align-self: center;
  }
  *[data-flex-align-md=end] {
    align-self: flex-end;
  }
  *[data-flex-align-md=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-md=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-flex-align-lg=start] {
    align-self: flex-start;
  }
  *[data-flex-align-lg=center] {
    align-self: center;
  }
  *[data-flex-align-lg=end] {
    align-self: flex-end;
  }
  *[data-flex-align-lg=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-lg=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-flex-align-xl=start] {
    align-self: flex-start;
  }
  *[data-flex-align-xl=center] {
    align-self: center;
  }
  *[data-flex-align-xl=end] {
    align-self: flex-end;
  }
  *[data-flex-align-xl=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-xl=stretch] {
    align-self: stretch;
  }
}
@media screen and (max-width: 599px) {
  *[data-flex-align-lt-sm=start] {
    align-self: flex-start;
  }
  *[data-flex-align-lt-sm=center] {
    align-self: center;
  }
  *[data-flex-align-lt-sm=end] {
    align-self: flex-end;
  }
  *[data-flex-align-lt-sm=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-lt-sm=stretch] {
    align-self: stretch;
  }
}
@media screen and (max-width: 959px) {
  *[data-flex-align-lt-md=start] {
    align-self: flex-start;
  }
  *[data-flex-align-lt-md=center] {
    align-self: center;
  }
  *[data-flex-align-lt-md=end] {
    align-self: flex-end;
  }
  *[data-flex-align-lt-md=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-lt-md=stretch] {
    align-self: stretch;
  }
}
@media screen and (max-width: 1279px) {
  *[data-flex-align-lt-lg=start] {
    align-self: flex-start;
  }
  *[data-flex-align-lt-lg=center] {
    align-self: center;
  }
  *[data-flex-align-lt-lg=end] {
    align-self: flex-end;
  }
  *[data-flex-align-lt-lg=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-lt-lg=stretch] {
    align-self: stretch;
  }
}
@media screen and (max-width: 1919px) {
  *[data-flex-align-lt-xl=start] {
    align-self: flex-start;
  }
  *[data-flex-align-lt-xl=center] {
    align-self: center;
  }
  *[data-flex-align-lt-xl=end] {
    align-self: flex-end;
  }
  *[data-flex-align-lt-xl=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-lt-xl=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 600px) {
  *[data-flex-align-gt-xs=start] {
    align-self: flex-start;
  }
  *[data-flex-align-gt-xs=center] {
    align-self: center;
  }
  *[data-flex-align-gt-xs=end] {
    align-self: flex-end;
  }
  *[data-flex-align-gt-xs=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-gt-xs=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 960px) {
  *[data-flex-align-gt-sm=start] {
    align-self: flex-start;
  }
  *[data-flex-align-gt-sm=center] {
    align-self: center;
  }
  *[data-flex-align-gt-sm=end] {
    align-self: flex-end;
  }
  *[data-flex-align-gt-sm=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-gt-sm=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 1280px) {
  *[data-flex-align-gt-md=start] {
    align-self: flex-start;
  }
  *[data-flex-align-gt-md=center] {
    align-self: center;
  }
  *[data-flex-align-gt-md=end] {
    align-self: flex-end;
  }
  *[data-flex-align-gt-md=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-gt-md=stretch] {
    align-self: stretch;
  }
}
@media screen and (min-width: 1920px) {
  *[data-flex-align-gt-lg=start] {
    align-self: flex-start;
  }
  *[data-flex-align-gt-lg=center] {
    align-self: center;
  }
  *[data-flex-align-gt-lg=end] {
    align-self: flex-end;
  }
  *[data-flex-align-gt-lg=baseline] {
    align-self: baseline;
  }
  *[data-flex-align-gt-lg=stretch] {
    align-self: stretch;
  }
}
*[data-layout-gap="1px"] {
  gap: 1px;
}

*[data-layout-gap="1em"] {
  gap: 1em;
}

*[data-layout-gap="1rem"] {
  gap: 1rem;
}

*[data-layout-gap="2px"] {
  gap: 2px;
}

*[data-layout-gap="2em"] {
  gap: 2em;
}

*[data-layout-gap="2rem"] {
  gap: 2rem;
}

*[data-layout-gap="3px"] {
  gap: 3px;
}

*[data-layout-gap="3em"] {
  gap: 3em;
}

*[data-layout-gap="3rem"] {
  gap: 3rem;
}

*[data-layout-gap="4px"] {
  gap: 4px;
}

*[data-layout-gap="4em"] {
  gap: 4em;
}

*[data-layout-gap="4rem"] {
  gap: 4rem;
}

*[data-layout-gap="5px"] {
  gap: 5px;
}

*[data-layout-gap="5em"] {
  gap: 5em;
}

*[data-layout-gap="5rem"] {
  gap: 5rem;
}

*[data-layout-gap="6px"] {
  gap: 6px;
}

*[data-layout-gap="6em"] {
  gap: 6em;
}

*[data-layout-gap="6rem"] {
  gap: 6rem;
}

*[data-layout-gap="7px"] {
  gap: 7px;
}

*[data-layout-gap="7em"] {
  gap: 7em;
}

*[data-layout-gap="7rem"] {
  gap: 7rem;
}

*[data-layout-gap="8px"] {
  gap: 8px;
}

*[data-layout-gap="8em"] {
  gap: 8em;
}

*[data-layout-gap="8rem"] {
  gap: 8rem;
}

*[data-layout-gap="9px"] {
  gap: 9px;
}

*[data-layout-gap="9em"] {
  gap: 9em;
}

*[data-layout-gap="9rem"] {
  gap: 9rem;
}

*[data-layout-gap="10px"] {
  gap: 10px;
}

*[data-layout-gap="10em"] {
  gap: 10em;
}

*[data-layout-gap="10rem"] {
  gap: 10rem;
}

*[data-layout-gap="11px"] {
  gap: 11px;
}

*[data-layout-gap="11em"] {
  gap: 11em;
}

*[data-layout-gap="11rem"] {
  gap: 11rem;
}

*[data-layout-gap="12px"] {
  gap: 12px;
}

*[data-layout-gap="12em"] {
  gap: 12em;
}

*[data-layout-gap="12rem"] {
  gap: 12rem;
}

*[data-layout-gap="13px"] {
  gap: 13px;
}

*[data-layout-gap="13em"] {
  gap: 13em;
}

*[data-layout-gap="13rem"] {
  gap: 13rem;
}

*[data-layout-gap="14px"] {
  gap: 14px;
}

*[data-layout-gap="14em"] {
  gap: 14em;
}

*[data-layout-gap="14rem"] {
  gap: 14rem;
}

*[data-layout-gap="15px"] {
  gap: 15px;
}

*[data-layout-gap="15em"] {
  gap: 15em;
}

*[data-layout-gap="15rem"] {
  gap: 15rem;
}

*[data-layout-gap="16px"] {
  gap: 16px;
}

*[data-layout-gap="16em"] {
  gap: 16em;
}

*[data-layout-gap="16rem"] {
  gap: 16rem;
}

@media screen and (max-width: 599px) {
  *[data-layout-gap-xs="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-xs="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-xs="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-xs="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-xs="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-xs="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-xs="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-xs="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-xs="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-xs="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-xs="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-xs="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-xs="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-xs="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-xs="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-xs="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-xs="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-xs="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-xs="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-xs="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-xs="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-xs="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-xs="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-xs="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-xs="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-xs="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-xs="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-xs="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-xs="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-xs="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-xs="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-xs="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-xs="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-xs="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-xs="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-xs="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-xs="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-xs="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-xs="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-xs="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-xs="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-xs="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-xs="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-xs="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-xs="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-xs="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-xs="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-xs="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-layout-gap-sm="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-sm="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-sm="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-sm="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-sm="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-sm="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-sm="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-sm="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-sm="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-sm="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-sm="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-sm="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-sm="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-sm="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-sm="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-sm="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-sm="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-sm="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-sm="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-sm="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-sm="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-sm="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-sm="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-sm="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-sm="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-sm="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-sm="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-sm="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-sm="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-sm="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-sm="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-sm="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-sm="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-sm="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-sm="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-sm="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-sm="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-sm="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-sm="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-sm="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-sm="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-sm="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-sm="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-sm="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-sm="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-sm="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-sm="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-sm="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-layout-gap-md="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-md="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-md="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-md="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-md="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-md="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-md="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-md="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-md="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-md="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-md="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-md="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-md="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-md="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-md="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-md="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-md="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-md="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-md="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-md="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-md="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-md="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-md="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-md="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-md="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-md="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-md="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-md="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-md="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-md="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-md="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-md="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-md="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-md="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-md="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-md="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-md="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-md="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-md="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-md="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-md="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-md="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-md="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-md="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-md="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-md="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-md="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-md="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-layout-gap-lg="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-lg="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-lg="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-lg="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-lg="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-lg="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-lg="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-lg="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-lg="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-lg="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-lg="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-lg="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-lg="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-lg="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-lg="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-lg="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-lg="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-lg="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-lg="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-lg="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-lg="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-lg="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-lg="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-lg="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-lg="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-lg="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-lg="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-lg="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-lg="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-lg="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-lg="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-lg="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-lg="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-lg="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-lg="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-lg="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-lg="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-lg="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-lg="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-lg="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-lg="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-lg="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-lg="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-lg="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-lg="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-lg="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-lg="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-lg="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-layout-gap-xl="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-xl="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-xl="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-xl="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-xl="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-xl="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-xl="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-xl="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-xl="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-xl="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-xl="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-xl="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-xl="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-xl="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-xl="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-xl="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-xl="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-xl="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-xl="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-xl="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-xl="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-xl="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-xl="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-xl="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-xl="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-xl="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-xl="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-xl="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-xl="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-xl="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-xl="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-xl="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-xl="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-xl="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-xl="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-xl="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-xl="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-xl="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-xl="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-xl="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-xl="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-xl="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-xl="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-xl="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-xl="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-xl="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-xl="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-xl="16rem"] {
    gap: 16rem;
  }
}
@media screen and (max-width: 599px) {
  *[data-layout-gap-lt-sm="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-lt-sm="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-lt-sm="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-lt-sm="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-lt-sm="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-lt-sm="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-lt-sm="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-lt-sm="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-lt-sm="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-lt-sm="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-lt-sm="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-lt-sm="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-lt-sm="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-lt-sm="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-lt-sm="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-lt-sm="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-lt-sm="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-lt-sm="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-lt-sm="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-lt-sm="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-lt-sm="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-lt-sm="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-lt-sm="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-lt-sm="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-lt-sm="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-lt-sm="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-lt-sm="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-lt-sm="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-lt-sm="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-lt-sm="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-lt-sm="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-lt-sm="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-lt-sm="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-lt-sm="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-lt-sm="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-lt-sm="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-lt-sm="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-lt-sm="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-lt-sm="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-lt-sm="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-lt-sm="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-lt-sm="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-lt-sm="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-lt-sm="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-lt-sm="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-lt-sm="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-lt-sm="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-lt-sm="16rem"] {
    gap: 16rem;
  }
}
@media screen and (max-width: 959px) {
  *[data-layout-gap-lt-md="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-lt-md="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-lt-md="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-lt-md="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-lt-md="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-lt-md="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-lt-md="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-lt-md="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-lt-md="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-lt-md="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-lt-md="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-lt-md="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-lt-md="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-lt-md="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-lt-md="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-lt-md="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-lt-md="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-lt-md="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-lt-md="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-lt-md="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-lt-md="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-lt-md="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-lt-md="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-lt-md="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-lt-md="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-lt-md="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-lt-md="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-lt-md="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-lt-md="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-lt-md="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-lt-md="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-lt-md="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-lt-md="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-lt-md="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-lt-md="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-lt-md="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-lt-md="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-lt-md="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-lt-md="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-lt-md="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-lt-md="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-lt-md="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-lt-md="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-lt-md="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-lt-md="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-lt-md="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-lt-md="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-lt-md="16rem"] {
    gap: 16rem;
  }
}
@media screen and (max-width: 1279px) {
  *[data-layout-gap-lt-lg="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-lt-lg="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-lt-lg="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-lt-lg="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-lt-lg="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-lt-lg="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-lt-lg="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-lt-lg="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-lt-lg="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-lt-lg="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-lt-lg="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-lt-lg="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-lt-lg="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-lt-lg="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-lt-lg="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-lt-lg="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-lt-lg="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-lt-lg="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-lt-lg="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-lt-lg="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-lt-lg="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-lt-lg="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-lt-lg="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-lt-lg="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-lt-lg="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-lt-lg="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-lt-lg="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-lt-lg="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-lt-lg="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-lt-lg="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-lt-lg="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-lt-lg="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-lt-lg="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-lt-lg="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-lt-lg="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-lt-lg="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-lt-lg="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-lt-lg="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-lt-lg="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-lt-lg="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-lt-lg="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-lt-lg="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-lt-lg="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-lt-lg="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-lt-lg="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-lt-lg="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-lt-lg="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-lt-lg="16rem"] {
    gap: 16rem;
  }
}
@media screen and (max-width: 1919px) {
  *[data-layout-gap-lt-xl="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-lt-xl="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-lt-xl="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-lt-xl="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-lt-xl="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-lt-xl="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-lt-xl="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-lt-xl="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-lt-xl="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-lt-xl="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-lt-xl="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-lt-xl="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-lt-xl="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-lt-xl="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-lt-xl="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-lt-xl="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-lt-xl="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-lt-xl="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-lt-xl="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-lt-xl="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-lt-xl="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-lt-xl="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-lt-xl="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-lt-xl="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-lt-xl="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-lt-xl="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-lt-xl="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-lt-xl="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-lt-xl="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-lt-xl="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-lt-xl="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-lt-xl="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-lt-xl="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-lt-xl="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-lt-xl="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-lt-xl="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-lt-xl="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-lt-xl="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-lt-xl="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-lt-xl="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-lt-xl="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-lt-xl="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-lt-xl="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-lt-xl="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-lt-xl="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-lt-xl="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-lt-xl="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-lt-xl="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 600px) {
  *[data-layout-gap-gt-xs="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-gt-xs="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-gt-xs="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-gt-xs="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-gt-xs="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-gt-xs="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-gt-xs="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-gt-xs="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-gt-xs="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-gt-xs="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-gt-xs="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-gt-xs="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-gt-xs="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-gt-xs="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-gt-xs="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-gt-xs="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-gt-xs="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-gt-xs="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-gt-xs="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-gt-xs="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-gt-xs="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-gt-xs="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-gt-xs="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-gt-xs="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-gt-xs="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-gt-xs="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-gt-xs="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-gt-xs="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-gt-xs="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-gt-xs="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-gt-xs="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-gt-xs="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-gt-xs="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-gt-xs="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-gt-xs="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-gt-xs="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-gt-xs="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-gt-xs="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-gt-xs="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-gt-xs="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-gt-xs="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-gt-xs="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-gt-xs="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-gt-xs="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-gt-xs="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-gt-xs="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-gt-xs="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-gt-xs="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 960px) {
  *[data-layout-gap-gt-sm="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-gt-sm="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-gt-sm="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-gt-sm="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-gt-sm="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-gt-sm="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-gt-sm="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-gt-sm="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-gt-sm="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-gt-sm="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-gt-sm="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-gt-sm="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-gt-sm="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-gt-sm="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-gt-sm="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-gt-sm="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-gt-sm="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-gt-sm="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-gt-sm="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-gt-sm="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-gt-sm="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-gt-sm="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-gt-sm="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-gt-sm="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-gt-sm="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-gt-sm="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-gt-sm="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-gt-sm="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-gt-sm="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-gt-sm="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-gt-sm="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-gt-sm="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-gt-sm="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-gt-sm="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-gt-sm="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-gt-sm="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-gt-sm="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-gt-sm="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-gt-sm="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-gt-sm="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-gt-sm="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-gt-sm="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-gt-sm="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-gt-sm="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-gt-sm="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-gt-sm="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-gt-sm="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-gt-sm="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 1280px) {
  *[data-layout-gap-gt-md="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-gt-md="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-gt-md="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-gt-md="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-gt-md="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-gt-md="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-gt-md="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-gt-md="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-gt-md="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-gt-md="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-gt-md="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-gt-md="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-gt-md="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-gt-md="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-gt-md="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-gt-md="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-gt-md="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-gt-md="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-gt-md="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-gt-md="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-gt-md="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-gt-md="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-gt-md="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-gt-md="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-gt-md="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-gt-md="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-gt-md="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-gt-md="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-gt-md="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-gt-md="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-gt-md="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-gt-md="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-gt-md="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-gt-md="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-gt-md="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-gt-md="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-gt-md="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-gt-md="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-gt-md="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-gt-md="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-gt-md="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-gt-md="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-gt-md="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-gt-md="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-gt-md="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-gt-md="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-gt-md="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-gt-md="16rem"] {
    gap: 16rem;
  }
}
@media screen and (min-width: 1920px) {
  *[data-layout-gap-gt-lg="1px"] {
    gap: 1px;
  }
  *[data-layout-gap-gt-lg="1em"] {
    gap: 1em;
  }
  *[data-layout-gap-gt-lg="1rem"] {
    gap: 1rem;
  }
  *[data-layout-gap-gt-lg="2px"] {
    gap: 2px;
  }
  *[data-layout-gap-gt-lg="2em"] {
    gap: 2em;
  }
  *[data-layout-gap-gt-lg="2rem"] {
    gap: 2rem;
  }
  *[data-layout-gap-gt-lg="3px"] {
    gap: 3px;
  }
  *[data-layout-gap-gt-lg="3em"] {
    gap: 3em;
  }
  *[data-layout-gap-gt-lg="3rem"] {
    gap: 3rem;
  }
  *[data-layout-gap-gt-lg="4px"] {
    gap: 4px;
  }
  *[data-layout-gap-gt-lg="4em"] {
    gap: 4em;
  }
  *[data-layout-gap-gt-lg="4rem"] {
    gap: 4rem;
  }
  *[data-layout-gap-gt-lg="5px"] {
    gap: 5px;
  }
  *[data-layout-gap-gt-lg="5em"] {
    gap: 5em;
  }
  *[data-layout-gap-gt-lg="5rem"] {
    gap: 5rem;
  }
  *[data-layout-gap-gt-lg="6px"] {
    gap: 6px;
  }
  *[data-layout-gap-gt-lg="6em"] {
    gap: 6em;
  }
  *[data-layout-gap-gt-lg="6rem"] {
    gap: 6rem;
  }
  *[data-layout-gap-gt-lg="7px"] {
    gap: 7px;
  }
  *[data-layout-gap-gt-lg="7em"] {
    gap: 7em;
  }
  *[data-layout-gap-gt-lg="7rem"] {
    gap: 7rem;
  }
  *[data-layout-gap-gt-lg="8px"] {
    gap: 8px;
  }
  *[data-layout-gap-gt-lg="8em"] {
    gap: 8em;
  }
  *[data-layout-gap-gt-lg="8rem"] {
    gap: 8rem;
  }
  *[data-layout-gap-gt-lg="9px"] {
    gap: 9px;
  }
  *[data-layout-gap-gt-lg="9em"] {
    gap: 9em;
  }
  *[data-layout-gap-gt-lg="9rem"] {
    gap: 9rem;
  }
  *[data-layout-gap-gt-lg="10px"] {
    gap: 10px;
  }
  *[data-layout-gap-gt-lg="10em"] {
    gap: 10em;
  }
  *[data-layout-gap-gt-lg="10rem"] {
    gap: 10rem;
  }
  *[data-layout-gap-gt-lg="11px"] {
    gap: 11px;
  }
  *[data-layout-gap-gt-lg="11em"] {
    gap: 11em;
  }
  *[data-layout-gap-gt-lg="11rem"] {
    gap: 11rem;
  }
  *[data-layout-gap-gt-lg="12px"] {
    gap: 12px;
  }
  *[data-layout-gap-gt-lg="12em"] {
    gap: 12em;
  }
  *[data-layout-gap-gt-lg="12rem"] {
    gap: 12rem;
  }
  *[data-layout-gap-gt-lg="13px"] {
    gap: 13px;
  }
  *[data-layout-gap-gt-lg="13em"] {
    gap: 13em;
  }
  *[data-layout-gap-gt-lg="13rem"] {
    gap: 13rem;
  }
  *[data-layout-gap-gt-lg="14px"] {
    gap: 14px;
  }
  *[data-layout-gap-gt-lg="14em"] {
    gap: 14em;
  }
  *[data-layout-gap-gt-lg="14rem"] {
    gap: 14rem;
  }
  *[data-layout-gap-gt-lg="15px"] {
    gap: 15px;
  }
  *[data-layout-gap-gt-lg="15em"] {
    gap: 15em;
  }
  *[data-layout-gap-gt-lg="15rem"] {
    gap: 15rem;
  }
  *[data-layout-gap-gt-lg="16px"] {
    gap: 16px;
  }
  *[data-layout-gap-gt-lg="16em"] {
    gap: 16em;
  }
  *[data-layout-gap-gt-lg="16rem"] {
    gap: 16rem;
  }
}
*[data-layout-gap="2.5px"] {
  gap: 2.5px;
}

*[data-layout-gap="2.5em"] {
  gap: 2.5em;
}

*[data-layout-gap="2.5rem"] {
  gap: 2.5rem;
}

*[data-layout-gap="32px"] {
  gap: 32px;
}

*[data-layout-gap="32em"] {
  gap: 32em;
}

*[data-layout-gap="32rem"] {
  gap: 32rem;
}

*[data-layout-gap="40px"] {
  gap: 40px;
}

*[data-layout-gap="40em"] {
  gap: 40em;
}

*[data-layout-gap="40rem"] {
  gap: 40rem;
}

*[data-layout-gap="48px"] {
  gap: 48px;
}

*[data-layout-gap="48em"] {
  gap: 48em;
}

*[data-layout-gap="48rem"] {
  gap: 48rem;
}

@media screen and (max-width: 599px) {
  *[data-layout-gap-xs="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-xs="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-xs="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-xs="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-xs="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-xs="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-xs="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-xs="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-xs="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-xs="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-xs="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-xs="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-layout-gap-sm="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-sm="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-sm="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-sm="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-sm="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-sm="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-sm="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-sm="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-sm="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-sm="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-sm="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-sm="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-layout-gap-md="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-md="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-md="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-md="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-md="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-md="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-md="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-md="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-md="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-md="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-md="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-md="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-layout-gap-lg="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-lg="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-lg="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-lg="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-lg="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-lg="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-lg="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-lg="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-lg="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-lg="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-lg="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-lg="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-layout-gap-xl="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-xl="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-xl="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-xl="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-xl="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-xl="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-xl="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-xl="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-xl="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-xl="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-xl="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-xl="48rem"] {
    gap: 48rem;
  }
}
@media screen and (max-width: 599px) {
  *[data-layout-gap-lt-sm="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-lt-sm="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-lt-sm="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-lt-sm="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-lt-sm="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-lt-sm="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-lt-sm="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-lt-sm="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-lt-sm="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-lt-sm="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-lt-sm="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-lt-sm="48rem"] {
    gap: 48rem;
  }
}
@media screen and (max-width: 959px) {
  *[data-layout-gap-lt-md="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-lt-md="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-lt-md="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-lt-md="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-lt-md="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-lt-md="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-lt-md="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-lt-md="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-lt-md="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-lt-md="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-lt-md="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-lt-md="48rem"] {
    gap: 48rem;
  }
}
@media screen and (max-width: 1279px) {
  *[data-layout-gap-lt-lg="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-lt-lg="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-lt-lg="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-lt-lg="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-lt-lg="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-lt-lg="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-lt-lg="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-lt-lg="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-lt-lg="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-lt-lg="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-lt-lg="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-lt-lg="48rem"] {
    gap: 48rem;
  }
}
@media screen and (max-width: 1919px) {
  *[data-layout-gap-lt-xl="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-lt-xl="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-lt-xl="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-lt-xl="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-lt-xl="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-lt-xl="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-lt-xl="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-lt-xl="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-lt-xl="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-lt-xl="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-lt-xl="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-lt-xl="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 600px) {
  *[data-layout-gap-gt-xs="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-gt-xs="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-gt-xs="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-gt-xs="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-gt-xs="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-gt-xs="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-gt-xs="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-gt-xs="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-gt-xs="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-gt-xs="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-gt-xs="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-gt-xs="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 960px) {
  *[data-layout-gap-gt-sm="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-gt-sm="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-gt-sm="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-gt-sm="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-gt-sm="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-gt-sm="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-gt-sm="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-gt-sm="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-gt-sm="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-gt-sm="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-gt-sm="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-gt-sm="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 1280px) {
  *[data-layout-gap-gt-md="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-gt-md="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-gt-md="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-gt-md="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-gt-md="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-gt-md="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-gt-md="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-gt-md="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-gt-md="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-gt-md="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-gt-md="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-gt-md="48rem"] {
    gap: 48rem;
  }
}
@media screen and (min-width: 1920px) {
  *[data-layout-gap-gt-lg="2.5px"] {
    gap: 2.5px;
  }
  *[data-layout-gap-gt-lg="2.5em"] {
    gap: 2.5em;
  }
  *[data-layout-gap-gt-lg="2.5rem"] {
    gap: 2.5rem;
  }
  *[data-layout-gap-gt-lg="32px"] {
    gap: 32px;
  }
  *[data-layout-gap-gt-lg="32em"] {
    gap: 32em;
  }
  *[data-layout-gap-gt-lg="32rem"] {
    gap: 32rem;
  }
  *[data-layout-gap-gt-lg="40px"] {
    gap: 40px;
  }
  *[data-layout-gap-gt-lg="40em"] {
    gap: 40em;
  }
  *[data-layout-gap-gt-lg="40rem"] {
    gap: 40rem;
  }
  *[data-layout-gap-gt-lg="48px"] {
    gap: 48px;
  }
  *[data-layout-gap-gt-lg="48em"] {
    gap: 48em;
  }
  *[data-layout-gap-gt-lg="48rem"] {
    gap: 48rem;
  }
}
*[data-flex="10"] {
  flex: 1 1 10%;
}

*[data-flex="15"] {
  flex: 1 1 15%;
}

*[data-flex="20"] {
  flex: 1 1 20%;
}

*[data-flex="25"] {
  flex: 1 1 25%;
}

*[data-flex="30"] {
  flex: 1 1 30%;
}

*[data-flex="35"] {
  flex: 1 1 35%;
}

*[data-flex="40"] {
  flex: 1 1 40%;
}

*[data-flex="45"] {
  flex: 1 1 45%;
}

*[data-flex="50"] {
  flex: 1 1 50%;
}

*[data-flex="55"] {
  flex: 1 1 55%;
}

*[data-flex="60"] {
  flex: 1 1 60%;
}

*[data-flex="65"] {
  flex: 1 1 65%;
}

*[data-flex="70"] {
  flex: 1 1 70%;
}

*[data-flex="75"] {
  flex: 1 1 75%;
}

*[data-flex="80"] {
  flex: 1 1 80%;
}

*[data-flex="85"] {
  flex: 1 1 85%;
}

*[data-flex="90"] {
  flex: 1 1 90%;
}

*[data-flex="95"] {
  flex: 1 1 95%;
}

*[data-flex="100"] {
  flex: 1 1 100%;
}

@media screen and (max-width: 599px) {
  *[data-flex-xs="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-xs="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-xs="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-xs="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-xs="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-xs="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-xs="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-xs="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-xs="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-xs="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-xs="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-xs="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-xs="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-xs="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-xs="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-xs="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-xs="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-xs="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-xs="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-flex-sm="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-sm="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-sm="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-sm="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-sm="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-sm="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-sm="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-sm="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-sm="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-sm="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-sm="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-sm="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-sm="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-sm="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-sm="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-sm="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-sm="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-sm="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-sm="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-flex-md="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-md="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-md="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-md="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-md="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-md="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-md="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-md="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-md="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-md="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-md="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-md="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-md="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-md="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-md="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-md="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-md="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-md="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-md="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-flex-lg="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-lg="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-lg="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-lg="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-lg="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-lg="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-lg="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-lg="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-lg="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-lg="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-lg="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-lg="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-lg="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-lg="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-lg="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-lg="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-lg="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-lg="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-lg="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-flex-xl="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-xl="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-xl="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-xl="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-xl="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-xl="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-xl="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-xl="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-xl="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-xl="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-xl="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-xl="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-xl="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-xl="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-xl="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-xl="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-xl="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-xl="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-xl="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 599px) {
  *[data-flex-lt-sm="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-lt-sm="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-lt-sm="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-lt-sm="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-lt-sm="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-lt-sm="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-lt-sm="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-lt-sm="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-lt-sm="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-lt-sm="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-lt-sm="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-lt-sm="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-lt-sm="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-lt-sm="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-lt-sm="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-lt-sm="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-lt-sm="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-lt-sm="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-lt-sm="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 959px) {
  *[data-flex-lt-md="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-lt-md="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-lt-md="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-lt-md="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-lt-md="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-lt-md="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-lt-md="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-lt-md="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-lt-md="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-lt-md="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-lt-md="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-lt-md="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-lt-md="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-lt-md="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-lt-md="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-lt-md="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-lt-md="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-lt-md="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-lt-md="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 1279px) {
  *[data-flex-lt-lg="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-lt-lg="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-lt-lg="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-lt-lg="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-lt-lg="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-lt-lg="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-lt-lg="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-lt-lg="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-lt-lg="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-lt-lg="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-lt-lg="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-lt-lg="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-lt-lg="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-lt-lg="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-lt-lg="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-lt-lg="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-lt-lg="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-lt-lg="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-lt-lg="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 1919px) {
  *[data-flex-lt-xl="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-lt-xl="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-lt-xl="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-lt-xl="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-lt-xl="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-lt-xl="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-lt-xl="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-lt-xl="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-lt-xl="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-lt-xl="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-lt-xl="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-lt-xl="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-lt-xl="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-lt-xl="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-lt-xl="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-lt-xl="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-lt-xl="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-lt-xl="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-lt-xl="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 600px) {
  *[data-flex-gt-xs="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-gt-xs="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-gt-xs="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-gt-xs="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-gt-xs="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-gt-xs="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-gt-xs="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-gt-xs="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-gt-xs="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-gt-xs="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-gt-xs="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-gt-xs="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-gt-xs="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-gt-xs="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-gt-xs="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-gt-xs="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-gt-xs="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-gt-xs="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-gt-xs="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 960px) {
  *[data-flex-gt-sm="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-gt-sm="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-gt-sm="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-gt-sm="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-gt-sm="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-gt-sm="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-gt-sm="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-gt-sm="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-gt-sm="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-gt-sm="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-gt-sm="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-gt-sm="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-gt-sm="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-gt-sm="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-gt-sm="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-gt-sm="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-gt-sm="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-gt-sm="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-gt-sm="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 1280px) {
  *[data-flex-gt-md="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-gt-md="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-gt-md="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-gt-md="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-gt-md="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-gt-md="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-gt-md="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-gt-md="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-gt-md="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-gt-md="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-gt-md="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-gt-md="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-gt-md="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-gt-md="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-gt-md="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-gt-md="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-gt-md="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-gt-md="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-gt-md="100"] {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 1920px) {
  *[data-flex-gt-lg="10"] {
    flex: 1 1 10%;
  }
  *[data-flex-gt-lg="15"] {
    flex: 1 1 15%;
  }
  *[data-flex-gt-lg="20"] {
    flex: 1 1 20%;
  }
  *[data-flex-gt-lg="25"] {
    flex: 1 1 25%;
  }
  *[data-flex-gt-lg="30"] {
    flex: 1 1 30%;
  }
  *[data-flex-gt-lg="35"] {
    flex: 1 1 35%;
  }
  *[data-flex-gt-lg="40"] {
    flex: 1 1 40%;
  }
  *[data-flex-gt-lg="45"] {
    flex: 1 1 45%;
  }
  *[data-flex-gt-lg="50"] {
    flex: 1 1 50%;
  }
  *[data-flex-gt-lg="55"] {
    flex: 1 1 55%;
  }
  *[data-flex-gt-lg="60"] {
    flex: 1 1 60%;
  }
  *[data-flex-gt-lg="65"] {
    flex: 1 1 65%;
  }
  *[data-flex-gt-lg="70"] {
    flex: 1 1 70%;
  }
  *[data-flex-gt-lg="75"] {
    flex: 1 1 75%;
  }
  *[data-flex-gt-lg="80"] {
    flex: 1 1 80%;
  }
  *[data-flex-gt-lg="85"] {
    flex: 1 1 85%;
  }
  *[data-flex-gt-lg="90"] {
    flex: 1 1 90%;
  }
  *[data-flex-gt-lg="95"] {
    flex: 1 1 95%;
  }
  *[data-flex-gt-lg="100"] {
    flex: 1 1 100%;
  }
}
*[data-flex="12"] {
  flex: 1 1 12%;
}

*[data-flex="14"] {
  flex: 1 1 14%;
}

*[data-flex="33"] {
  flex: 1 1 33%;
}

*[data-flex="42"] {
  flex: 1 1 42%;
}

*[data-flex="43"] {
  flex: 1 1 43%;
}

*[data-flex="66"] {
  flex: 1 1 66%;
}

@media screen and (max-width: 599px) {
  *[data-flex-xs="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-xs="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-xs="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-xs="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-xs="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-xs="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  *[data-flex-sm="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-sm="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-sm="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-sm="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-sm="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-sm="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  *[data-flex-md="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-md="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-md="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-md="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-md="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-md="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  *[data-flex-lg="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-lg="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-lg="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-lg="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-lg="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-lg="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  *[data-flex-xl="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-xl="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-xl="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-xl="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-xl="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-xl="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (max-width: 599px) {
  *[data-flex-lt-sm="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-lt-sm="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-lt-sm="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-lt-sm="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-lt-sm="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-lt-sm="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (max-width: 959px) {
  *[data-flex-lt-md="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-lt-md="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-lt-md="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-lt-md="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-lt-md="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-lt-md="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (max-width: 1279px) {
  *[data-flex-lt-lg="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-lt-lg="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-lt-lg="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-lt-lg="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-lt-lg="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-lt-lg="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (max-width: 1919px) {
  *[data-flex-lt-xl="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-lt-xl="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-lt-xl="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-lt-xl="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-lt-xl="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-lt-xl="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 600px) {
  *[data-flex-gt-xs="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-gt-xs="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-gt-xs="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-gt-xs="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-gt-xs="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-gt-xs="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 960px) {
  *[data-flex-gt-sm="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-gt-sm="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-gt-sm="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-gt-sm="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-gt-sm="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-gt-sm="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 1280px) {
  *[data-flex-gt-md="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-gt-md="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-gt-md="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-gt-md="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-gt-md="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-gt-md="66"] {
    flex: 1 1 66%;
  }
}
@media screen and (min-width: 1920px) {
  *[data-flex-gt-lg="12"] {
    flex: 1 1 12%;
  }
  *[data-flex-gt-lg="14"] {
    flex: 1 1 14%;
  }
  *[data-flex-gt-lg="33"] {
    flex: 1 1 33%;
  }
  *[data-flex-gt-lg="42"] {
    flex: 1 1 42%;
  }
  *[data-flex-gt-lg="43"] {
    flex: 1 1 43%;
  }
  *[data-flex-gt-lg="66"] {
    flex: 1 1 66%;
  }
}